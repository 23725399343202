import React from 'react';
import Layout from 'components/layout';

const Terms = () => (
  <Layout>
    <div className="container mt-3 pt-3 mt-lg-5 pt-lg-5">
      <h1 className="mb-2 mb-lg-4">Terms & Conditions</h1>
      <div className="row">
        <div className="col-12 col-lg-6">
          <p className="mb-3 mb-lg-5">
            These Terms of Use were last updated on 2nd October 2018. This
            website at dealer.selliton.co.uk (the “Website”), is operated by, or
            on behalf of, Kent Motor Cars Ltd (“Sell it on”, “we” or “us”) to
            provide users free valuation and purchase services for car-related
            transactions (“Services”).
          </p>
          <h3 className="mb-2 mb-lg-4">Agreeing to the Terms</h3>
          <p className="mb-3 mb-lg-5">
            By using our Website, you agree to comply with and be bound by the
            following terms and conditions, and any other documents referred to
            therein (the “Terms of Use”). If you do not agree to these Terms of
            Use, you must not use this Website.
          </p>
          <h3 className="mb-2 mb-lg-4">Changing the Terms of Use</h3>
          <p className="mb-3 mb-lg-5">
            We reserve the right to amend these Terms of Use from time to time.
            The amended Terms of Use will be posted on this Website, and will
            become effective from the date they are posted. Your continued use
            of this Website after any such post will constitute your acceptance
            of, and agreement to, any changes.
          </p>
          <h3 className="mb-2 mb-lg-4">General</h3>
          <p className="mb-3 mb-lg-5">
            Nothing in these Terms of Use intends to deprive you of any rights
            you may be granted under applicable mandatory laws (including
            mandatory consumer protection laws).
          </p>
          <p className="mb-3 mb-lg-5">
            If we do not immediately insist that you do something you are
            required to under these Terms of Use, or if we delay in taking steps
            against you in respect of you breaking these Terms of Use, that will
            not prevent us from taking steps against you at a later date.
          </p>
          <p className="mb-3 mb-lg-5">
            If any provision or part-provision of these Terms of Use is or
            becomes invalid, illegal or unenforceable, it shall be deemed
            modified to the minimum extent necessary to make it valid, legal and
            enforceable. If such modification is not possible, the relevant
            provision or part-provision shall be deemed deleted. Any such
            modification to or deletion shall not affect the validity and
            enforceability of the rest of these Terms of Use.
          </p>
          <p className="mb-3 mb-lg-5">
            Without prejudice to any other rights or remedies that we may have,
            you acknowledge and agree that damages alone would not be an
            adequate remedy for any breach of these Terms of Use by you.
            Accordingly, we shall be entitled to the remedies of injunction,
            specific performance or other equitable relief for any threatened or
            actual breach of these Terms of Use.
          </p>
          <p className="mb-3 mb-lg-5">
            These Terms of Use are governed by English law (including
            non-contractual disputes or claims) and you agree to that the
            English courts shall have non-exclusive jurisdiction over any
            dispute that may arise between you and us in relation to these Terms
            of Use.
          </p>
          <h3 className="mb-2 mb-lg-4">GDPR compliance statement</h3>
          <p className="mb-3 mb-lg-5">
            Sell It On LTD respects and complies with the EU General Data
            Protection Regulations (GDPR).
          </p>
          <p className="mb-3 mb-lg-5">
            Some of the key ways we comply with these regulations are:
          </p>
          <h3 className="mb-2 mb-lg-4">Consent</h3>
          <p className="mb-3 mb-lg-5">
            We explain what you’re consenting to clearly and without ‘legalese’
            and ask that you explicitly consent to contact from us.
          </p>
          <h3 className="mb-2 mb-lg-4">Breach Notification</h3>
          <p className="mb-3 mb-lg-5">
            In the event of a breach we will notify affected users within 72
            hours of first having become aware of the breach.
          </p>
          <h3 className="mb-2 mb-lg-4">Right to Access</h3>
          <p className="mb-3 mb-lg-5">
            Users can request confirmation as to whether or not personal data
            concerning them is being processed, where and for what purpose.
            Further, we shall provide a copy of the personal data, free of
            charge, in an electronic format.
          </p>
          <h3 className="mb-2 mb-lg-4">Right to be Forgotten</h3>
          <p className="mb-3 mb-lg-5">
            Once we have compared your (the subjects) rights to the public
            interest in the availability of the data, we may delete your
            personal data where you have requested this.
          </p>
          <h3 className="mb-2 mb-lg-4">Data Portability</h3>
          <p className="mb-3 mb-lg-5">
            We allow you to receive the personal data concerning you, which we
            will provide in a commonly used and machine readable format and you
            have the right to transmit that data to another controller’.
          </p>
          <h3 className="mb-2 mb-lg-4">Privacy by Design</h3>
          <p className="mb-3 mb-lg-5">
            We implement appropriate technical and organisational measures, in
            an effective way, in order to meet the requirements of this
            Regulation and protect the rights of data subjects. We hold and
            process only the data absolutely necessary for the completion of our
            duties (data minimisation), as well as limiting the access to
            personal data to those needing to act out the processing.
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default Terms;
